import Button from 'ui/button';
import { OsrFilterState } from 'business/resources/osr/services/types';
import { useGenerateExcelFile } from 'business/resources/osr/services/useGenerateExcelFile';
import OsrTabs from 'business/resources/osr/components/common/OsrTabs';
import Flex from 'ui/flex';
import { RangePicker } from 'ui/date/datePicker';
import { endOfDay, endOfYear, startOfYear } from 'date-fns';
import Spacer from 'ui/spacer';
import { Checkbox } from 'antd';
import V2Table from 'ui/v2/table/table';
import {
  useGetAfuRenewed,
  useGetAfuToRenew,
  useGetProofOfUseRenewed,
  useGetProofOfUseToRenew,
} from 'business/resources/osr/services/trademarkOsr.service';
import useTableColumn from 'technical/table/useTableColumn';
import {
  AFU_RENEWED_COLUMNS,
  AFU_TO_RENEW_COLUMNS,
  PROOF_OF_USED_RENEWED_COLUMNS,
  PROOF_OF_USED_TO_RENEW_COLUMNS,
} from './trademarkOsr.constant';
import SearchBar from 'ui/v2/tableActions/search';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';

interface ITrademarkAffidavits {
  filters: OsrFilterState;
  onChangeRangeDate: (from: Date | null, to: Date | null) => void;
}

const AffidavitList = (props: ITrademarkAffidavits) => {
  const { generateExcel } = useGenerateExcelFile('trademark', props.filters);

  const afuToRenewTableColumns = useTableColumn(AFU_TO_RENEW_COLUMNS);
  const afuToRenew = useGetAfuToRenew(
    {
      limit: afuToRenewTableColumns.paginationState.pageSize,
      offset:
        (afuToRenewTableColumns.paginationState.current - 1) *
        afuToRenewTableColumns.paginationState.pageSize,
      dueDate: props.filters.dueDate,
      companyRef: props.filters.companyRef,
      countryCode: props.filters.countryCode,
    },
    afuToRenewTableColumns.orderByState,
  );

  const afuRenewedTableColumns = useTableColumn(AFU_RENEWED_COLUMNS);
  const afuRenewed = useGetAfuRenewed(
    {
      limit: afuRenewedTableColumns.paginationState.pageSize,
      offset:
        (afuRenewedTableColumns.paginationState.current - 1) *
        afuRenewedTableColumns.paginationState.pageSize,
      dueDate: props.filters.dueDate,
      companyRef: props.filters.companyRef,
      countryCode: props.filters.countryCode,
    },
    afuRenewedTableColumns.orderByState,
  );

  const proofOfUseToRenewTableColumns = useTableColumn(
    PROOF_OF_USED_TO_RENEW_COLUMNS,
  );
  const proofOfUseToRenew = useGetProofOfUseToRenew(
    {
      limit: proofOfUseToRenewTableColumns.paginationState.pageSize,
      offset:
        (proofOfUseToRenewTableColumns.paginationState.current - 1) *
        proofOfUseToRenewTableColumns.paginationState.pageSize,
      dueDate: props.filters.dueDate,
      companyRef: props.filters.companyRef,
      countryCode: props.filters.countryCode,
    },
    proofOfUseToRenewTableColumns.orderByState,
  );

  const proofOfUseRenewedTableColumns = useTableColumn(
    PROOF_OF_USED_RENEWED_COLUMNS,
  );
  const proofOfUseRenewed = useGetProofOfUseRenewed(
    {
      limit: proofOfUseRenewedTableColumns.paginationState.pageSize,
      offset:
        (proofOfUseRenewedTableColumns.paginationState.current - 1) *
        proofOfUseRenewedTableColumns.paginationState.pageSize,
      dueDate: props.filters.dueDate,
      companyRef: props.filters.companyRef,
      countryCode: props.filters.countryCode,
    },
    proofOfUseRenewedTableColumns.orderByState,
  );

  return (
    <OsrTabs
      tabsItems={[
        {
          key: 'affidavitsTable',
          label: `Affidavits à traiter (${afuToRenew.total})`,
          children: (
            <V2Table
              columns={afuToRenewTableColumns.tableColumns}
              dataSource={afuToRenew.data}
              onChange={afuToRenewTableColumns.handlePagination}
              loading={afuToRenew.loading}
              pageSizeOptions={['10', '50', '100', '500', '1000']}
              pagination={{
                current: afuToRenewTableColumns.paginationState.current,
                pageSize: afuToRenewTableColumns.paginationState.pageSize,
                total: afuToRenew.total,
              }}
              actions={
                <Spacer align="center" size="small">
                  <SearchBar
                    value={afuToRenew.searchAfuToRenew}
                    onDebouncedChange={(e) => afuToRenew.setSearchAfuToRenew(e)}
                  />
                  <Button
                    className="searchButton"
                    type="primary"
                    onClick={() => generateExcel('AFFIDAVIT')}
                  >
                    Exporter les Affidavits
                  </Button>
                  <EditColumnsButton
                    initialColumns={AFU_TO_RENEW_COLUMNS}
                    visibleColumns={afuToRenewTableColumns.visibleColumns}
                    onChange={afuToRenewTableColumns.toggleColumnVisibility}
                  />
                  <>
                    <Checkbox
                      defaultChecked={afuToRenew.firstAffidavitEvent}
                      onChange={() =>
                        afuToRenew.setFirstAffidavitEvent(
                          !afuToRenew.firstAffidavitEvent,
                        )
                      }
                    >
                      1er délai affidavit
                    </Checkbox>
                    <Checkbox
                      defaultChecked={afuToRenew.lastDeadlineAffidavit}
                      onChange={() =>
                        afuToRenew.setLastDeadlineAffidavit(
                          !afuToRenew.lastDeadlineAffidavit,
                        )
                      }
                    >
                      Dernier délai affidavit
                    </Checkbox>

                    <Checkbox
                      defaultChecked={afuToRenew.excludingArgentina}
                      onChange={() =>
                        afuToRenew.setExcludingArgentina(
                          !afuToRenew.excludingArgentina,
                        )
                      }
                    >
                      Hors Argentine
                    </Checkbox>
                  </>
                </Spacer>
              }
            />
          ),
        },
        {
          key: 'affidavitDoneTable',
          label: `Affidavits traités (${afuRenewed.total})`,
          children: (
            <V2Table
              columns={afuRenewedTableColumns.tableColumns}
              dataSource={afuRenewed.data}
              onChange={afuRenewedTableColumns.handlePagination}
              loading={afuRenewed.loading}
              pageSizeOptions={['10', '50', '100', '500', '1000']}
              pagination={{
                current: afuRenewedTableColumns.paginationState.current,
                pageSize: afuRenewedTableColumns.paginationState.pageSize,
                total: afuRenewed.total,
              }}
              actions={
                <Spacer size="small" align="center">
                  <SearchBar
                    value={afuRenewed.searchAfuRenewed}
                    onDebouncedChange={(e) => afuRenewed.setSearchAfuRenewed(e)}
                  />
                  <Button
                    className="searchButton"
                    type="primary"
                    onClick={() => generateExcel('AFFIDAVIT')}
                  >
                    Exporter les Affidavits
                  </Button>
                  <EditColumnsButton
                    initialColumns={AFU_RENEWED_COLUMNS}
                    visibleColumns={afuRenewedTableColumns.visibleColumns}
                    onChange={afuRenewedTableColumns.toggleColumnVisibility}
                  />
                  <Checkbox
                    defaultChecked={afuRenewed.affidavitAr}
                    onChange={() =>
                      afuRenewed.setAffidavitAr(!afuRenewed.affidavitAr)
                    }
                  >
                    Sans AR
                  </Checkbox>
                </Spacer>
              }
            />
          ),
        },
        {
          key: 'trademark_proof_of_use_to_renew_table',
          label: `P. d’usage liée à Ren. à traiter (${proofOfUseToRenew.total})`,
          children: (
            <V2Table
              columns={proofOfUseToRenewTableColumns.tableColumns}
              dataSource={proofOfUseToRenew.data}
              onChange={proofOfUseToRenewTableColumns.handlePagination}
              loading={proofOfUseToRenew.loading}
              pageSizeOptions={['10', '50', '100', '500', '1000']}
              pagination={{
                current: proofOfUseToRenewTableColumns.paginationState.current,
                pageSize:
                  proofOfUseToRenewTableColumns.paginationState.pageSize,
                total: proofOfUseToRenew.total,
              }}
              actions={
                <Spacer size="small" align="center">
                  <SearchBar
                    value={proofOfUseToRenew.searchProofOfUsedToRenew}
                    onDebouncedChange={(e) =>
                      proofOfUseToRenew.setSearchProofOfUsedToRenew(e)
                    }
                  />
                  <Button
                    className="searchButton"
                    type="primary"
                    onClick={() => generateExcel('AFFIDAVIT')}
                  >
                    Exporter les Affidavits
                  </Button>
                  <EditColumnsButton
                    initialColumns={PROOF_OF_USED_TO_RENEW_COLUMNS}
                    visibleColumns={
                      proofOfUseToRenewTableColumns.visibleColumns
                    }
                    onChange={
                      proofOfUseToRenewTableColumns.toggleColumnVisibility
                    }
                  />
                  <Checkbox
                    defaultChecked={proofOfUseToRenew.excludingArgentina}
                    onChange={() =>
                      proofOfUseToRenew.setExcludingArgentina(
                        !proofOfUseToRenew.excludingArgentina,
                      )
                    }
                  >
                    Hors Argentine
                  </Checkbox>
                </Spacer>
              }
            />
          ),
        },
        {
          key: 'trademark_proof_of_use_renewed_table',
          label: `P. d’usage liées à Ren. traitées (${proofOfUseRenewed.total})`,
          children: (
            <V2Table
              columns={proofOfUseToRenewTableColumns.tableColumns}
              dataSource={proofOfUseRenewed.data}
              onChange={proofOfUseToRenewTableColumns.handlePagination}
              loading={proofOfUseRenewed.loading}
              pageSizeOptions={['10', '50', '100', '500', '1000']}
              pagination={{
                current: proofOfUseRenewedTableColumns.paginationState.current,
                pageSize:
                  proofOfUseRenewedTableColumns.paginationState.pageSize,
                total: proofOfUseRenewed.total,
              }}
              actions={
                <Spacer size="small" align="center">
                  <SearchBar
                    value={proofOfUseRenewed.searchProofOfUsedRenewed}
                    onDebouncedChange={(e) =>
                      proofOfUseRenewed.setSearchProofOfUsedRenewed(e)
                    }
                  />
                  <Button
                    className="searchButton"
                    type="primary"
                    onClick={() => generateExcel('AFFIDAVIT')}
                  >
                    Exporter les Affidavits
                  </Button>
                  <EditColumnsButton
                    initialColumns={PROOF_OF_USED_RENEWED_COLUMNS}
                    visibleColumns={
                      proofOfUseRenewedTableColumns.visibleColumns
                    }
                    onChange={
                      proofOfUseRenewedTableColumns.toggleColumnVisibility
                    }
                  />
                </Spacer>
              }
            />
          ),
        },
      ]}
      actions={
        <Spacer size="small" direction="vertical">
          <Flex column style={{ width: '33%' }}>
            <Spacer direction="vertical" size="small">
              <RangePicker
                allowClear
                defaultValue={[startOfYear(new Date()), endOfYear(new Date())]}
                onChange={(e) => {
                  props.onChangeRangeDate(
                    e && e[0] ? new Date(endOfDay(e[0])) : e?.[0] ?? null,
                    e && e[1] ? new Date(endOfDay(e[1])) : e?.[1] ?? null,
                  );
                }}
              />
            </Spacer>
          </Flex>
        </Spacer>
      }
    />
  );
};

export default AffidavitList;
