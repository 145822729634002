import { FilePdfOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import { PatentDocument } from 'business/resources/patent/types';
import { useOpenOfficialDocument } from 'business/resources/patent/services/useOpenOfficialDocument';
import { useTranslation } from 'react-i18next';

interface OfficialDocumentsProps {
  documents: PatentDocument[];
  loading?: boolean;
}

const OfficialDocuments: React.FC<OfficialDocumentsProps> = ({
  documents,
  loading,
}) => {
  const { t } = useTranslation();
  const { openOfficalDocument } = useOpenOfficialDocument();

  console.log('documents === ', documents);
  return (
    <List
      dataSource={documents}
      loading={loading}
      renderItem={({ id, type, publicationNumber }) => (
        <List.Item
          actions={[
            <Button onClick={() => openOfficalDocument(id)} type="primary">
              <FilePdfOutlined />
              {t('common.openFile')}
            </Button>,
          ]}
        >
          <span>
            {t('patent.officialDocuments.type', {
              context:
                publicationNumber.includes('US') && type === 'B2'
                  ? `${type}US`
                  : type,
            })}
          </span>
        </List.Item>
      )}
    />
  );
};

export default OfficialDocuments;
