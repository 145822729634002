import { Table as AntdTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import Spacer from 'ui/spacer';

import classnames from 'classnames';
import classes from 'ui/table/index.module.scss';
import React from 'react';
import Flex from 'ui/flex';

interface ITableProps {
  columns: TableProps<any>['columns'];
  dataSource: TableProps<any>['dataSource'];
  onChange: TableProps<any>['onChange'];
  pagination: TableProps<any>['pagination'];
  pageSizeOptions?: string[];
  actions?: React.ReactNode;
  loading?: boolean;
  rowSelection?: TableProps<any>['rowSelection'];
  rowKey?: string;
  dataTestId?: string;
}

const Table = (props: ITableProps) => {
  return (
    <Spacer direction="vertical" size="middle">
      <Flex>{props.actions ? props.actions : null}</Flex>
      <div className={classnames(classes.tableContainer, 'elevation1')}>
        <AntdTable
          data-test-id={props.dataTestId}
          rowClassName={(_record, index) => {
            return classnames({ [classes.tableRowLight]: index % 2 === 0 });
          }}
          rowKey={props.rowKey ?? 'id'}
          size="small"
          loading={props.loading}
          className={classes.table}
          columns={props.columns}
          dataSource={props.dataSource}
          onChange={props.onChange}
          rowSelection={props.rowSelection}
          pagination={{
            position: ['bottomLeft'],
            pageSizeOptions: props.pageSizeOptions
              ? props.pageSizeOptions
              : ['10', '30', '50'],
            ...props.pagination,
          }}
        />
      </div>
    </Spacer>
  );
};

export default Table;
